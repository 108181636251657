exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-business-solution-tsx": () => import("./../../../src/templates/business-solution.tsx" /* webpackChunkName: "component---src-templates-business-solution-tsx" */),
  "component---src-templates-car-info-tsx": () => import("./../../../src/templates/car-info.tsx" /* webpackChunkName: "component---src-templates-car-info-tsx" */),
  "component---src-templates-ev-user-solution-tsx": () => import("./../../../src/templates/ev-user-solution.tsx" /* webpackChunkName: "component---src-templates-ev-user-solution-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

